<template>
  <div class="chart-bg">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3">
          <InputText
            textFloat="Search"
            placeholder="Search Name, Member ID, Telephone, Email"
            type="text"
            v-model="filter.Search"
            name="search"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date From"
            name="Transaction-date-from"
            ref="Transaction-date-from"
            v-model="filter.TransactionDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date To"
            name="Transaction-date-to"
            ref="Transaction-date-to"
            v-model="filter.TransactionDateTo"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Used Date From"
            name="used-date-from"
            ref="used-date-from"
            v-model="filter.UsedDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Used Date To"
            name="used-date-to"
            ref="used-date-to"
            v-model="filter.UsedDateTo"
          />
        </div>
        <div class="mb-3">
          <label class="main-label"> Buy Branch</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.BranchFilter"
            :options="options.branches"
            placeholder="Select Branch"
            track-by="id"
            label="branchName"
          ></multiselect>
        </div>
      </template>
    </SideBarFilter>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <b-container class="py-3">
      <b-row class="chart-gap">
        <template v-for="chart in chartList">
          <b-col
            cols="12"
            lg="6"
            :key="chart.name"
            class="resize"
            v-if="chart.isChart"
          >
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="chart-title-name">{{ chart.name }}</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>

                <template v-if="chart.isFetch">
                  <ChartLoading :id="'loading-' + chart.name" type="line" />
                </template>
                <template v-else>
                  <BasketChart
                    :id="chart.name"
                    :chartData="chart.data"
                    :text="chart.text"
                    class="mt-auto"
                    :hideLegend="chart.hideLegend"
                    :label="chart.label"
                    :type="chart.type"
                    :filterValue="chart.filterValue"
                    :xText="chart.xText"
                    :isStacked="chart.isStacked"
                  />
                </template>
              </div>
            </b-card>
          </b-col>
        </template>

        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Branch</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[2].filter"
                  :key="chartFilter + '-' + chartList[2].name"
                  @click="handleChartFilter(chartFilter, chartList[2])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>

            <b-table
              fixed
              striped
              hover
              :fields="fields.branch"
              :items="branch.items"
              :busy="branch.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="(ctx) => sortingChanged(ctx, chartList[2])"
              :sort-by.sync="branch.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="branch.filter.sortDesc"
            >
              <template v-slot:cell(outstanding_qty)="data">
                <span>
                  {{ data.item.outstanding_qty | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(outstanding_amount)="data">
                <span>
                  {{ data.item.outstanding_amount | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(outstanding_credit)="data">
                <span>
                  {{ data.item.outstanding_credit | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(total_sales)="data">
                <span>
                  {{ data.item.total_sales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="stat[data.field.key]" class="text-center">
                  <span v-if="data.field.key == 'outstanding_qty'">
                    {{ stat[data.field.key] | numeral("0,0") }}</span
                  >
                  <span v-else>
                    {{ stat[data.field.key] | numeral("0,0.00") }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="
                (val) => handleChangeTake(val, 'branch', chartList[2])
              "
              @pagination="(val) => pagination(val, 'branch', chartList[2])"
              :filter="branch.filter"
              :rows="branch.rows"
              class="p-2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Course by Year</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[3].filter"
                  :key="chartFilter + '-' + chartList[3].name"
                  @click="handleChartFilter(chartFilter, chartList[3])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>

            <b-table
              fixed
              striped
              hover
              :fields="fields.year"
              :items="year.items"
              :busy="year.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="(ctx) => sortingChanged(ctx, chartList[3])"
              :sort-by.sync="year.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="year.filter.sortDesc"
            >
              <template v-slot:cell(outstanding_qty)="data">
                <span>
                  {{ data.item.outstanding_qty | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(outstanding_amount)="data">
                <span>
                  {{ data.item.outstanding_amount | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(outstanding_credit)="data">
                <span>
                  {{ data.item.outstanding_credit | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(total_sales)="data">
                <span>
                  {{ data.item.total_sales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="stat[data.field.key]" class="text-center">
                  <span v-if="data.field.key == 'outstanding_qty'">
                    {{ stat[data.field.key] | numeral("0,0") }}</span
                  >
                  <span v-else>
                    {{ stat[data.field.key] | numeral("0,0.00") }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="
                (val) => handleChangeTake(val, 'year', chartList[3])
              "
              @pagination="(val) => pagination(val, 'year', chartList[3])"
              :filter="year.filter"
              :rows="year.rows"
              class="p-2"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import ChartLoading from "@/components/loading/ChartLoading";
import BasketChart from "chart";
import Multiselect from "vue-multiselect";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,

    ChartLoading,
    BasketChart,
  },
  data() {
    return {
      isLoading: true,
      statColumn: [
        {
          name: "Total Outstanding Amount",
          key: "outstanding_amount",
          isPercent: false,
          isDecimal: true,
        },

        {
          name: "Outstanding Credit",
          key: "outstanding_credit",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "Outstanding QTY",
          key: "outstanding_qty",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      filter: {
        Search: "",
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
      filterFreeze: {
        Search: "",
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
      options: {
        branches: [],
      },
      chartList: [
        {
          name: "Top 10 Outstanding Quantity by Branch",
          filterValue: "Month",
          text: "Baht",
          filter: ["Export"],
          label: [],
          data: [],
          export: "branch_top10qty",
          get: () => this.getTop10Quantity(),
          type: "bar",
          isFetch: false,
          isChart: true,
          hideLegend: true,
        },
        {
          name: "Top 10 Outstanding Income by Branch",
          filterValue: "Month",
          text: "Baht",
          filter: ["Export"],
          label: [],
          data: [],
          export: "branch_top10income",
          get: () => this.getTop10product(),
          type: "bar",
          isFetch: false,
          isChart: true,
          hideLegend: true,
        },
        {
          name: "Burn by Branch",
          get: () => this.getBranch(),
          key: "branch",
          export: "branch",
          filter: ["Export"],
        },
        {
          name: "Course by Year",
          get: () => this.getBurnYear(),
          key: "year",
          export: "group_year",
          filter: ["Export"],
        },
      ],
      fields: {
        branch: [
          {
            key: "branch_name",
            label: "Branch",
            class: "w-20pc",
            sortable: true,
            text: "Total",
          },
          {
            key: "outstanding_qty",
            label: "Qty Sold",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "outstanding_amount",
            label: "Outstanding Amount",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "outstanding_credit",
            label: "Outstanding Credit",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "total_sales",
            label: "Total Sales",
            thClass: "w-20pc",
            sortable: true,
          },
        ],
        year: [
          {
            key: "group_year",
            label: "Year",
            class: "w-20pc",
            sortable: true,
            text: "Total",
          },
          {
            key: "outstanding_qty",
            label: "Qty Sold",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "outstanding_amount",
            label: "Outstanding Amount",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "outstanding_credit",
            label: "Outstanding Credit",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "total_sales",
            label: "Total Sales",
            thClass: "w-20pc",
            sortable: true,
          },
        ],
      },
      branch: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      year: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        outstanding_amount: 0,
        outstanding_credit: 0,
        outstanding_qty: 0,
        total_sales: 0,
        treatment_sold: 0,
      },
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
    };
  },
  async mounted() {
    this.initDateFilter();
    this.getFilter();
    this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getStat(),
        // this.getTop10Quantity(),
        // this.getTop10Income(),
        this.getBranch(),
        this.getBurnYear(),
      ]);
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DD");
      this.initFilterDate.TransactionDateFrom = moment(toDay)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      this.initFilterDate.TransactionDateTo =
        moment(toDay).format("YYYY-MM-DD");
      // this.initFilterDate.MinDate = moment(toDay).subtract(1, "years").format();
      this.initFilterDate.MaxDate = toDay;
      this.filter.TransactionDateFrom = this.initFilterDate.TransactionDateFrom;
      this.filter.TransactionDateTo = this.initFilterDate.TransactionDateTo;
      this.filterFreeze = { ...this.filter };
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "M-YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date).format("DD/MM/YYYY");
      return value;
    },
    async getTop10Quantity() {
      this.chartList[0].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/service/outstanding/branch_top10qty`,
        this.filterFreeze
      );

      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = res.data.detail.map((el) => el.branch_name);
      dataSet = [
        {
          label: "Quantity",
          type: "bar",
          data: res.data.detail.map((el) => el.outstanding_qty),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getTop10() {
      this.chartList[0].isFetch = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_API}/dashboard/service/outstanding/analysis`,
        this.filterFreeze
      );

      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = res.data.detail.map((el) => el.branch_name);
      dataSet = [
        {
          label: "Quantity",
          type: "bar",
          data: res.data.detail.map((el) => el.outstanding_qty),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getTop10Income() {
      this.chartList[1].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/service/outstanding/branch_top10income`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = res.data.detail.map((el) => el.branch_name);
      dataSet = [
        {
          label: "Income ",
          type: "bar",
          data: res.data.detail.map((el) => el.total_sales),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];
      this.chartList[1].label = label;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
    },
    async getStat() {
      this.chartList[0].isFetch = true;
      this.chartList[1].isFetch = true;
      this.statLoading = true;
      const res = await this.$report.post(
        `/dashboard/service/outstanding/analysis`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = res.data.detail.top10qty.map((el) => el.branch_name);
      dataSet = [
        {
          label: "Quantity",
          type: "bar",
          data: res.data.detail.top10qty.map((el) => el.outstanding_qty),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];
      let label2 = [];
      var dataSet2 = [];
      let color2 = getRandomColor();

      label2 = res.data.detail.top10income.map((el) => el.branch_name);
      dataSet2 = [
        {
          label: "Income ",
          type: "bar",
          data: res.data.detail.top10income.map((el) => el.total_sales),
          borderColor: color2,
          backgroundColor: color2,
          fill: false,
        },
      ];
      this.chartList[1].label = label2;
      this.chartList[1].data = dataSet2;
      this.chartList[1].isFetch = false;
      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
      this.stat = res.data.detail;

      this.statLoading = false;
    },
    async getBranch() {
      this.branch.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/outstanding/branch`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.branch.filter.page,
          RecordPerPage: this.branch.filter.take,
          SortColumnId: this.branch.filter.SortColumnId,
          SortType: this.branch.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.branch.items = res.data.detail.data;
      this.branch.rows = res.data.detail.total;
      this.branch.isBusy = false;
    },
    async getBurnYear() {
      this.year.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/outstanding/group_year`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.year.filter.page,
          RecordPerPage: this.year.filter.take,
          SortColumnId: this.year.filter.SortColumnId,
          SortType: this.year.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.year.items = res.data.detail.data;
      this.year.rows = res.data.detail.total;
      this.year.isBusy = false;
    },
    pagination(val, key, chart) {
      this[key].filter.page = val;
      chart.get();
    },
    handleChangeTake(val, key, chart) {
      this[key].filter.take = val;
      this[key].filter.page = 1;
      chart.get();
    },
    async getFilter() {
      const res = await this.$report(`/dashboard/Filter/Branch`);

      this.options.branches = res.data.detail;
    },
    async export(path) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/dashboard/service/outstanding/report/${path}`,
        this.filterFreeze,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filterFreeze.TypeDateFilter = value;
      await chart.get();
    },
    clearFilter() {
      this.filter = {
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      };
      this.filterFreeze = {
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      };
      this.$refs.filter.hide();
      this.$store.dispatch("clearFilter");
      this.filterActions();
    },
    filterActions() {
      if (this.filter.TransactionDateFrom)
        this.filter.TransactionDateFrom = this.$moment(
          this.filter.TransactionDateFrom
        ).format("YYYY-MM-DD");
      if (this.filter.TransactionDateTo)
        this.filter.TransactionDateTo = this.$moment(
          this.filter.TransactionDateTo
        ).format("YYYY-MM-DD");
      if (this.filter.UsedDateFrom)
        this.filter.UsedDateFrom = this.$moment(
          this.filter.UsedDateFrom
        ).format("YYYY-MM-DD");
      if (this.filter.UsedDateTo)
        this.filter.UsedDateTo = this.$moment(this.filter.UsedDateTo).format(
          "YYYY-MM-DD"
        );

      this.filterFreeze = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }

      this.getData();
    },
    async sortingChanged(ctx, chart) {
      let key = chart.key;
      let index = this.fields[key].findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this[key].filter.SortType &&
        !ctx.sortDesc &&
        this[key].filter.SortColumnId == index
      ) {
        this[key].filter.SortColumnId = 0;
        this[key].filter.SortColumnId = 0;
        this[key].filter.sortBy = "";
        this[key].filter.sortDesc = "";
      } else {
        this[key].filter.SortColumnId = index;
        this[key].filter.SortType = ctx.sortDesc;
      }
      await chart.get();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
</style>
